<template>
  <div
    id="app"
    class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column"
  >
    <Navigation></Navigation>
    <slot></slot>
    <footer class="mastfoot mt-auto text-center">
      <div class="inner">
        <p>Copyright © 2020 Vsevolod Klementjev. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Public",
  components: {
    Navigation
  }
};
</script>

<style lang="sass">
@import '@/sass/base.sass'
</style>
