import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Tilemaps from "../views/Tilemaps.vue";
import NotFound from "../views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      auth: false
    }
  },
  {
    path: "/itilemaps",
    name: "itilemaps",
    component: Tilemaps
    // meta: {
    //   auth: true
    // }
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Dashboard.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/admin/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Profile.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/admin/messages",
    name: "messages",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Messages.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/admin/itm_dau",
    name: "itm_dau",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/MapsDau.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/admin/*",
    name: "admin404",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin404.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "*",
    name: "404",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes
});

export default router;
