<template>
  <Public>
    <div role="main">
      <h1>Contact Us</h1>
      <b-alert
        :show="messageSent"
        variant="success"
        dismissible
        @dismissed="hideSuccess()"
        >Message has been sent!</b-alert
      >
      <b-alert
        :show="failed"
        variant="danger"
        dismissible
        @dismissed="failed = false"
        >Something went wrong!</b-alert
      >
      <b-form @submit="onSubmit" v-if="show" novalidate>
        <b-form-group
          id="input-group-1"
          label="Email address"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
            :state="emailValidation"
          ></b-form-input>
          <b-form-invalid-feedback :state="emailValidation">
            Provide valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="input-group-2" label="Subject" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.subject"
            required
            placeholder="Enter subject"
            :state="subjectValidation"
          ></b-form-input>
          <b-form-invalid-feedback :state="subjectValidation">
            Subject can't be empty and must not be longer than 255 characters.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="input-group-3" label="Message" label-for="input-3">
          <b-form-textarea
            id="input-3"
            v-model="form.message"
            required
            placeholder="Enter message"
            rows="5"
            :state="messageValidation"
          ></b-form-textarea>
          <b-form-invalid-feedback :state="messageValidation">
            Message can't be empty.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button style="width: 5rem" type="submit" variant="primary"
          ><div v-if="!loading">Send</div>
          <b-spinner v-if="loading" small></b-spinner>
        </b-button>
        <b-form-text
          class="text-muted"
          v-html="captchaDescription"
        ></b-form-text>

        <!-- listen to verify event emited by the recaptcha component -->
        <recaptcha ref="recaptcha" @verify="recaptchaFinished"></recaptcha>
      </b-form>
    </div>
  </Public>
</template>

<script>
import Public from "@/components/Public.vue";
import Recaptcha from "@/components/Recaptcha.vue";

export default {
  name: "Contact",
  components: {
    Public,
    Recaptcha
  },
  data() {
    return {
      form: {
        email: "",
        subject: "",
        message: "",
        recaptcha: ""
      },
      show: true,
      messageSent: false,
      loading: false,
      validated: true,
      failed: false
    };
  },
  methods: {
    recaptchaFinished(response) {
      this.form.recaptcha = response;

      var _this = this;
      this.axios
        .post("messages", this.form)
        .then(response => {
          _this.loading = false;
          _this.show = false;
          if (response.status != 200) {
            throw new Error(response.status);
          }
          _this.messageSent = true;
        })
        .catch(() => {
          _this.loading = false;
          _this.failed = true;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (this.loading) return;

      this.validated = false;

      if (this.allValidated) {
        this.loading = true;
        this.$refs.recaptcha.execute();
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    hideSuccess() {
      this.messageSent = false;
      this.show = true;

      // reset everything
      this.form.email = "";
      this.form.subject = "";
      this.form.message = "";
      this.form.recaptcha = "";
      this.validated = true;
    }
  },
  computed: {
    emailValidation() {
      if (this.validated) return null;
      return this.validateEmail(this.form.email);
    },
    subjectValidation() {
      if (this.validated) return null;
      return this.form.subject.length > 0 && this.form.subject.length <= 255;
    },
    messageValidation() {
      if (this.validated) return null;
      return this.form.message.length > 0;
    },
    allValidated() {
      return (
        this.emailValidation && this.subjectValidation && this.messageValidation
      );
    },
    captchaDescription() {
      return "This site is protected by hCaptcha and its <a href='https://hcaptcha.com/privacy'>Privacy Policy</a> and <a href='https://hcaptcha.com/terms'>Terms of Service</a> apply.";
    }
  }
};
</script>

<style lang="sass">
.hcaptcha-badge
    visibility: hidden
</style>
