<template>
  <Public>
    <div role="main" class="inner cover text-center">
      <h1 class="cover-heading">404</h1>
      <p class="lead">
        The page you were looking for can't be found.
      </p>
    </div>
  </Public>
</template>

<script>
import Public from "@/components/Public.vue";

export default {
  name: "NotFound",
  components: {
    Public
  }
};
</script>
