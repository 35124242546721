<template>
  <Public>
    <div role="main">
      <div class="jumbotron">
        <div class="container">
          <h1 class="display-3">iTileMaps 3</h1>
          <p>First ever game development tool for iOS!</p>
          <p>
            iTileMaps is powerfull tile map creation tool. Works with any 2D
            game genre, whether it's RPG or sidescroller or anything else.
            Specifically designed for iOS it allows creating maps very fast
            wherever you are. No need to change anything in your game because it
            uses popular tmx file format and works well with
            <a href="https://www.mapeditor.org" target="_blank">Tiled</a>.
          </p>
          <p>TODO:</p>
          <ul>
            <li>Screenshots</li>
            <li>Proper video</li>
            <li>QR code for AppClip</li>
            <li>TestFlight Badge</li>
            <li>AppStore Badge</li>
            <li>Icon before name</li>
          </ul>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3>Features:</h3>
            <ul>
              <li>Simple and popular xml-based format - tmx</li>
              <li>Orthogonal and isometric maps</li>
              <li>Tile rotation and flipping</li>
              <li>Custom objects for per pixel precision placement</li>
              <li>Custom properties for tiles, layers, objects or map</li>
              <li>
                Time saving editing tools like fill bucket, clone and recent
                tiles tools
              </li>
              <li>Ability to export created map directly to your game</li>
              <li>Import and export files in Files app</li>
              <li>
                Draws slower when in idle state, means higher battery life
              </li>
            </ul>
          </div>

          <div class="col-md-6">
            <h3>Limitations (for advanced users):</h3>
            <ul>
              <li>Hexagonal maps are not supported</li>
              <li>Only 1 tileset can be used per layer</li>
              <li>
                Tileset info is saved in single file with map, no external
                tileset info supported
              </li>
              <li>
                Tilesets can't have some color as transperent, only alpha
                channel is supported
              </li>
              <li>Tile objects are not supported</li>
              <li>Images cannot be saved in map file, only externally</li>
              <li>
                Layer data must be in gzip/zlib compression encoded with base64
              </li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h2>Video</h2>
              <video controls preload="auto">
                <source src="itm.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>
                Video not working? Try
                <a href="https://www.youtube.com/watch?v=WelHyj-BnAs"
                  >youtube</a
                >
                instead.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Public>
</template>

<script>
import Public from "@/components/Public.vue";

export default {
  name: "Tilemaps",
  components: {
    Public
  },
  metaInfo() {
    return {
      title: "iTileMaps",
      meta: [
        {
          name: "apple-itunes-app",
          content: "app-id=432784227"
        }
      ]
    };
  }
};
</script>

<style lang="sass">
video
  width: 100%
  height: auto
</style>
