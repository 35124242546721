import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import auth from "./auth";

import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import VueAuth from "@websanova/vue-auth";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;
Vue.router = router;

Vue.use(VueAxios, axios);
axios.defaults.baseURL = `/api/v1`;
Vue.use(VueAuth, auth);
Vue.use(VueMeta);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
