import axios from "@websanova/vue-auth/drivers/http/axios.1.x";
import router from "@websanova/vue-auth/drivers/router/vue-router.2.x";

// Auth base configuration some of this options
// can be override in method calls
const config = {
  auth: {
    request: function(req, token) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: "Bearer " + token
      });

      if (req.url === this.options.refreshData.url) {
        req.data = {
          token: this.token(this.options.refreshTokenName),
          secret: this.token(this.options.refreshTokenSecretName)
        };
      }
    },

    response: function(res) {
      // console.log(res.data);

      if (
        res.data !== undefined &&
        res.data.token !== undefined &&
        res.data.id !== undefined &&
        res.data.refreshToken !== undefined &&
        res.data.refreshTokenSecret !== undefined &&
        res.data.expiresAt !== undefined
      ) {
        this.token(this.options.refreshTokenName, res.data.refreshToken);
        this.token(
          this.options.refreshTokenSecretName,
          res.data.refreshTokenSecret
        );
        this.token("expiresAt", res.data.expiresAt);
        // console.log(this.token("expiresAt"));

        return res.data.token.trim();
      }
    }
  },
  http: axios,
  router: router,
  loginData: {
    url: "auth",
    method: "POST",
    redirect: "/admin",
    fetchUser: true
  },
  refreshData: {
    url: "auth/refresh",
    method: "POST",
    enabled: true,
    interval: 90
  },
  fetchData: { url: "users/me", method: "GET", enabled: true },
  logoutData: {
    url: "auth/logout",
    method: "POST",
    redirect: "/",
    makeRequest: false
  },
  parseUserData: function(data) {
    return data;
  },
  tokenStore: ["localStorage"],
  refreshTokenName: "refresh_token",
  refreshTokenSecretName: "refresh_token_secret",
  tokenExpired: function() {
    // console.log(
    //   new Date(this.token("expiresAt")) +
    //     " <= " +
    //     new Date() +
    //     " ? " +
    //     new Date(this.token("expiresAt")) <=
    //     new Date()
    // );
    if (new Date(this.token("expiresAt")) <= new Date()) return true;
    return false;
  }
};

export default config;
