<template>
  <div id="h-captcha" class="h-captcha" :data-sitekey="sitekey"></div>
</template>

<script>
export default {
  data() {
    return {
      sitekey: "565e9196-2fc1-48d5-beb5-b8baf84965bb",
      widgetId: 0
    };
  },
  methods: {
    execute() {
      window.hcaptcha.execute(this.widgetId);
    },
    reset() {
      window.hcaptcha.reset(this.widgetId);
    },
    render() {
      if (window.hcaptcha) {
        var style = "dark";

        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: light)").matches
        ) {
          style = "light";
        }

        this.widgetId = window.hcaptcha.render("h-captcha", {
          sitekey: this.sitekey,
          size: "invisible",
          theme: style,
          // the callback executed when the user solve the recaptcha
          callback: response => {
            // emit an event called verify with the response as payload
            this.$emit("verify", response);
            // reset the recaptcha widget so you can execute it again
            this.reset();
          }
        });
      }
    },
    renderWait() {
      setTimeout(() => {
        if (
          typeof window.hcaptcha !== "undefined" &&
          typeof window.hcaptcha.render !== "undefined"
        )
          this.render();
        else this.renderWait();
      }, 200);
    }
  },
  mounted() {
    // render the recaptcha widget when the component is mounted
    if (typeof window.hcaptcha === "undefined") {
      var script = document.createElement("script");
      script.src = "https://hcaptcha.com/1/api.js?render=explicit";
      script.onload = this.renderWait;
      document.head.appendChild(script);
    } else this.render();
  },
  beforeDestroy() {
    this.reset();
  }
};
</script>
