<template>
  <header class="masthead mb-auto">
    <div class="inner">
      <h3 class="masthead-brand">KleMiX.com</h3>
      <nav class="nav nav-masthead justify-content-center">
        <router-link to="/" class="nav-link" exact>Home</router-link>
        <router-link to="/contact" class="nav-link">Contact</router-link>
        <router-link to="/itilemaps" class="nav-link" v-if="authed"
          >iTileMaps</router-link
        >
        <router-link to="/admin" class="nav-link" v-if="authed"
          >Admin</router-link
        >
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Navigation",
  mounted() {
    this.authed = this.$auth.check();
  },
  data() {
    return {
      authed: false
    };
  }
};
</script>
