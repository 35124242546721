<template>
  <Public>
    <div role="main" class="inner cover text-center">
      <h1 class="cover-heading">Under reconstruction</h1>
      <p class="lead">
        The site is going reconstruction, soon more content will be available.
        But feel free to contact me, it works.
      </p>
    </div>
  </Public>
</template>

<script>
import Public from "@/components/Public.vue";

export default {
  name: "Home",
  components: {
    Public
  }
};
</script>
